import React from "react";
import { Global, Theme } from "@emotion/react";
import { ThemeProvider } from "@mui/material";
import { GlobalStyle, main, muiInputFormsCSS, muiTheme, styled } from "@iventis/styles";
import { LoginPanelContainer } from "@iventis/components/src/login-containers";
import "@iventis/styles/src/font-awesome";
import { initialiseObservabilityAndMonitoring } from "@iventis/observability-and-monitoring";
import { SignUpPage } from "./components/sign-up";

const signUpTheme: Theme = {
    ...main,
    primaryColors: {
        ...main.primaryColors,
        focus: "#E63888",
        subdued70: "#881149",
    },
    typographyColors: {
        ...main.typographyColors,
        core: "#4F6179",
    },
    otherColors: {
        ...main.otherColors,
        separatorLight: "#56588A",
    },
};
declare const VERSION: string;

if (VERSION != null) {
    // eslint-disable-next-line no-console
    console.log(`Sign up version: ${VERSION}`);
}

// Do not enable monitoring for local host or development environments
const enableMonitoring = !(
    ["localhost", "frontend"].includes(window.location.hostname.toLowerCase()) || window.location.hostname.toLowerCase().includes("sign-up-dev.iventis.com")
);

initialiseObservabilityAndMonitoring(enableMonitoring, window.location.hostname);

export const App = () => (
    <ThemeProvider
        theme={muiTheme(signUpTheme, "light", "ltr", {
            typography: {
                fontFamily: "Poppins",
                allVariants: {
                    textTransform: "none",
                },
            },
        })}
    >
        <SignUpContainer>
            <StyledLoginPanelContainer>
                <SignUpPage />
            </StyledLoginPanelContainer>
        </SignUpContainer>
        <Global styles={GlobalStyle} />
    </ThemeProvider>
);

const StyledLoginPanelContainer = styled(LoginPanelContainer)`
    ${muiInputFormsCSS}
`;

const SignUpContainer = styled.div`
    width: 100%;
    height: 100%;
    overflow-x: auto;
`;
