/* eslint-disable camelcase */
export const errors = {
    required: "Required",
    must_be_less_than: "Must be {{ characters }} characters or less",
    problem_with_our_servers: "There was a problem with our servers. Please try again later.",
    problem_with_our_servers_code: "There was a problem with our servers. The error code is {{ code }}.",
    problem_with_data_submitted: "There was a problem with the data that was submitted to the server",
    connecting_with_our_servers: "There was a problem connecting to our servers. Please try again later.",
    timeout_connecting_servers: "Your request timed out. Please try again.",
    internet_connection_issue: "There is a problem with the connection to the server. Please check your internet connection",
    copy_code: "Copy Code",
    polygon_edit_error: "Polygons must have at least 3 nodes",
    linestring_edit_error: "Lines must have at least 2 nodes",
    unknown_edit_error: "An unknown error occured",
    billing_error: "There was a problem with the billing for your project",
    service_unavailable: "This service is currently unavailable, please contact a project administrator",
    three_nested: "You cannot create a folder more than 3 levels deep",
    error_boundaries: {
        title_large: "Oops! Something went wrong.",
        title_small: "Something went wrong!",
        error_message: "Something's gone wrong and our team has been alerted. If the problem persists, contact support on ",
        error_code: "Error code: {{ code }}",
        contact: "Contact us",
        refresh: "Refresh",
        reload: "Reload",
    },
} as const;
