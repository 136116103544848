/* eslint-disable import/no-mutable-exports */
import * as Sentry from "@sentry/react";
import { getEnvironment } from "./environment/environment-helpers";

declare const VERSION: string;

export let sessionReplayIntegration: ReturnType<typeof Sentry.replayIntegration> | null = null;

export let canvasReplayIntegration: ReturnType<typeof Sentry.replayCanvasIntegration> | null = null;

export function initialiseObservabilityAndMonitoring(enabled: boolean, appUrl: string) {
    if (!enabled) {
        return;
    }

    sessionReplayIntegration = Sentry.replayIntegration({
        maskAllText: false,
        maskAllInputs: false,
        blockAllMedia: false,
        block: ["[type='password']"],
    });

    canvasReplayIntegration = Sentry.replayCanvasIntegration();

    Sentry.init({
        dsn: "https://714bc62603359a8e3ec14212ac77db77@o4507453584637952.ingest.de.sentry.io/4507453594533968",
        integrations: [sessionReplayIntegration, canvasReplayIntegration],
        replaysSessionSampleRate: 1.0,
        replaysOnErrorSampleRate: 1.0,
        environment: getEnvironment(appUrl),
        release: VERSION,
    });
}
