import * as Sentry from "@sentry/react";
import { setMonitoringTag } from "../tags/set-monitoring-tag";
import { TagName } from "../tags/tag-types";
import { MetricName } from "../metrics/metric-types";
import { setMonitoringUserScreenSize } from "./user-screen-size";
import { UserTags } from "./user-types";

let userTags: UserTags;

/** Sets monitoring data for a user for session replays and metrics, optional if they are signed into a project */
export function setMonitoringUser(user: { firstName: string; lastName: string; email: string; id: string; externalUser: boolean }, project?: { id: string; name: string }) {
    const { firstName, lastName, email, id, externalUser } = user;
    const userName = `${user.firstName} ${user.lastName}`;
    const isIventisStaff = isIventisEmployee(email);

    Sentry.setUser({ email, name: `${firstName} ${lastName}`, id });
    // User name is not set by sentry so add it manually
    setMonitoringTag(TagName.USER_NAME, userName);
    setMonitoringTag(TagName.IVENTIS_EMPLOYEE, isIventisStaff);
    setMonitoringTag(TagName.EXTERNAL_USER, externalUser);

    if (project != null) {
        const { id: projectId, name: projectName } = project;
        setMonitoringTag(TagName.PROJECT_NAME, projectName);
        setMonitoringTag(TagName.PROJECT_ID, projectId);

        userTags = {
            [TagName.USER_ID]: id,
            [TagName.USER_EMAIL]: email,
            [TagName.USER_NAME]: userName,
            [TagName.PROJECT_ID]: projectId,
            [TagName.PROJECT_NAME]: projectName,
            [TagName.EXTERNAL_USER]: externalUser,
            [TagName.IVENTIS_EMPLOYEE]: isIventisStaff,
        };

        // Set user session metrics
        Sentry.metrics.set(MetricName.USER_SESSION_UNIQUE_COUNT, id, { tags: userTags });
        Sentry.metrics.increment(MetricName.USER_SESSION_COUNT, 1, { tags: userTags });
    }

    setMonitoringUserScreenSize();
}

function isIventisEmployee(email: string): boolean {
    if (email == null) {
        return false;
    }
    return email.endsWith("@iventis.com") || email.endsWith("@iventis.co.uk");
}

export function getUserTags(): UserTags {
    return userTags;
}
