import { common } from "./common";
import { errors } from "./errors";
import { library, library2 } from "./library";
import { login, login2 } from "./login";
import { mapContent, mapContent2, mapContent3, mapContent4, mapContent5, mapContent6, mapContent7, mapContent8 } from "./map";
import { people } from "./people";
import { permissions } from "./permissions";
import { projects } from "./projects";
import { runSheet } from "./run-sheet";
import { schedule } from "./schedule";
import { schedule2 } from "./schedule2";
import { schedule3 } from "./schedule3";
import { settings, settings2, settings3 } from "./settings";
import { subscriptionPlan, subscriptionPlan2, subscriptionPlan3 } from "./subscription-plan";
import { table } from "./table";
import { timeRules } from "./time-rules";
import { attributes } from "./attributes";
import { comments } from "./comments";
import { notifications } from "./notifications";

const content = {
    common,
    library,
    library2,
    map: mapContent,
    map2: mapContent2,
    map3: mapContent3,
    map4: mapContent4,
    map5: mapContent5,
    map6: mapContent6,
    map7: mapContent7,
    map8: mapContent8,
    settings,
    settings2,
    settings3,
    errors,
    permissions,
    people,
    projects,
    login,
    login2,
    schedule,
    schedule2,
    schedule3,
    table,
    runsheet: runSheet,
    timerules: timeRules,
    subscriptionplan: subscriptionPlan,
    subscriptionplan2: subscriptionPlan2,
    subscriptionplan3: subscriptionPlan3,
    attributes,
    comments,
    notifications,
};
export default content;
