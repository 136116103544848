import { TagName, TagValue } from "../tags/tag-types";

export enum MetricName {
    /** Counts every time a user selects/logs into the application */
    USER_SESSION_COUNT = "user_session_count",
    /** Counts each time a user with a different Id logs into the application */
    USER_SESSION_UNIQUE_COUNT = "user_session_unique_count",
    MAP_LOADING = "map_loading",
    MAPBOX_LOADING = "mapbox_loading",
    PLAN_CREATED = "plan_created",
    PLAN_LOADED = "plan_loaded",
    PLAN_EXPORTED = "plan_exported",
    MAP_OBJECTS_EXPORTED = "map_objects_exported",
    MAP_OBJECTS_IMPORTED = "map_objects_imported",
    AREA_LAYER_CREATED = "area_layer_created",
    LINE_LAYER_CREATED = "line_layer_created",
    POINT_LAYER_CREATED = "point_layer_created",
    ICON_LAYER_CREATED = "icon_layer_created",
    MODEL_LAYER_CREATED = "model_layer_created",
    LINE_MODEL_LAYER_CREATED = "line_model_layer_created",
    DATA_TABLE_USED = "data_table_used",
    PLAN_SHARED = "plan_shared",
    PLAN_SEARCHED = "plan_searched",
    DATE_FILTER_USED = "date_filter_used",
    DIGITAL_TWIN_USED = "digital_twin_used",
    DELETE_LAYER = "delete_layer",
    IMPORT_LAYER = "import_layer",
    DISTANCE_ANALYSIS_USED = "distance_analysis_used",
    WALK_TIME_ANALYSIS_USED = "walk_time_analysis_used",
    FLOW_RATE_ANALYSIS_USED = "flow_rate_analysis_used",
    AREA_ANALYSIS_USED = "area_analysis_used",
    CAPACITY_ANALYSIS_USED = "capacity_analysis_used",
    COUNT_ANALYSIS_USED = "count_analysis_used",
    PLAN_3D_TERRAIN_USED = "plan_3d_terrain_used",
    PLAN_3D_BUILDINGS_USED = "plan_3d_buildings_used",
    BACKGROUND_MAP_SELECTED = "background_map_selected",
    SITEMAP_SELECTOR_USED = "sitemap_selector_used",
    SITEMAP_LEVEL_USED = "sitemap_level_used",
    COMMENT_CREATED = "comment_created",
    COMMENT_SELECTED = "comment_selected",
    MAP_OBJECT_CREATED = "map_object_created",
    COPY_MAP_OBJECT_USED = "copy_map_object_used",
    FIXED_SHAPE_MAP_OBJECT_USED = "fixed_shape_map_object_used",
    ROUTE_MAP_OBJECT_USED = "route_map_object_used",
    DRAW_MAP_OBJECT_USED = "draw_map_object_used",
    NOTIFICATIONS_VIEWED = "notifications_viewed",
    CREATE_DATAFIELD = "create_datafield",
    DELETE_DATAFIELD = "delete_datafield",
}

export enum MetricTagName {
    MAP_ID = "map.id",
    MAP_NAME = "map.name",
    BACKGROUND_MAP_ID = "background_map.id",
    BACKGROUND_MAP_NAME = "background_map.name",
    LAYER_ID = "layer.id",
    LAYER_NAME = "layer.name",
    COMMENT_ID = "comment.id",
    EXPORT_FORMAT = "export.format",
    EXPORT_PAGE_SIZE = "export.page_size",
    EXPORT_PAGE_ORIENTATION = "export.page_orientation",
    IMPORT_FORMAT = "import.format",
    DATA_FIELD_TYPE = "datafield.type",
    DATA_FIELD_ID = "datafield.id",
    PROJECT_DATA_FIELD = "datafield.project_datafield",
}

export enum MetricEventName {
    MAP_LOADED = "MAP_LOADED",
    SIDEBAR_LOADED = "SIDEBAR_LOADED",
    MAPBOX_LOADED = "MAPBOX_LOADED",
}

export type MetricTags = Partial<Record<TagName | MetricTagName, TagValue>>;
