export const library = {
    main: "Main",
    my_library: "My Library",
    node_names: {
        Folder: "Folder",
        PersonalMappingLibrary: "My Workspace",
        MappingLibrary: "Team Workspace",
        Map: "Plan",
        Layer: "Layer",
        MapObject: "Object",
        Schedule: "Schedule",
        ScheduleVersion: "Schedule",
    },
    static_library_names: {
        recently_viewed: "Recently viewed",
        favourites: "Favourites",
    },
    static_library_empty: {
        recently_viewed: "Plans that you've recently opened will show here",
        favourites: "Plans and folders that you favourite will show here so you can access them easily",
    },
    static_library_empty_button: {
        recently_viewed: "Get started with your first plan",
        favourites: "Browse the project plans",
    },
    node_content: {
        last_activity: "Latest activity {{ latestActivityDate }}",
        created: "Created {{ createdAt }}",
        last_viewed: "Last viewed {{ viewedAt }}",
    },
    create_node: {
        saveInFolder: "Save in folder",
        title: "Create {{ nodeName }}",
        fieldLabel: "{{ nodeName }} name",
        creating: "Creating {{ nodeName }}...",
        maxInputChars: "Name must be no longer than 500 characters",
    },
    permissions: "Edit {{ nodeName }} Permissions",
    update_node: {
        title: "Rename {{ nodeName }}",
        description: "This {{ nodeName }} will be updated for everyone.",
        updating: "Renaming {{ nodeName }}...",
    },
    delete_node: {
        singular_title: "Delete a {{ nodeType }}",
        singular_description: "Deleting this {{ nodeType }} will delete all of the content too.",
        singular_confirm_button: "Delete {{ nodeType }}",
        multi_title: "Delete {{ count }} items",
        multi_description: "Deleting these items will delete all of the content too.",
        multi_confirm_button: "Delete items",
    },
    overflow: {
        description: "Click to open options for {{ nodeName }}",
        rename: "Rename {{ nodeName }}",
        delete: "Delete {{ nodeName }}",
        permissions: "Change {{ nodeName }} Permissions",
    },
    empty_node: "{{ nodeName }} is empty",
    folder_browser_view: {
        while_in: {
            // If currently viewing by list, text should suggest viewing by tiles (and vice versa)
            list: "View as tiles",
            tile: "View as list",
        },
    },
    sort: {
        folder_first_asc: "Folder first (A-Z)",
        name_asc: "Name (A-Z)",
    },
    saveLocation: {
        visibleYou: "Visible only to you",
        visibleOtherTeamMembers: "Visible to other team members",
        visibleWholeProject: "Visible to everyone",
        teamMembers: "team members",
    },
    hints: {
        PersonalMappingLibrary: {
            title: "My Workspace",
            first_paragraph: 'Plans you create here will only be available to you. \nUse the "Create a plan" button to make your first plan.',
            second_paragraph: 'When you\'re ready to share Plans with your team, you can use the "Team Workspace".',
        },
        MappingLibrary: {
            title: "Team Workspace",
            first_paragraph: "Plans created here are shared across your team. Use this space when collaborating with other team members.",
            second_paragraph: "You can also organise your plans into folders, such as for different departments.",
        },
        Map: {
            title: "Layers",
            first_paragraph: "Create a layer to start drawing on the map. Layers are used to organise your plans.",
        },
        Folder: {
            title: "Folder is empty",
            first_paragraph: 'Use the "Create a plan" button above to add a plan here.',
        },
        ScheduleVersion: {
            title: "{{ type }}",
            first_paragraph: 'Create a "{{ type }}" to start scheduling events.',
        },
    },
    hints_static_libraries: {
        recently_viewed: {
            title: "Recently viewed",
            first_paragraph: "Your recently viewed plans will appear here.",
        },
        favourites: {
            title: "Favourites",
            first_paragraph: "Your favourited plans and folders will appear here.",
        },
    },
    remove_node: {
        singular_title: "Remove a {{ nodeType }}",
        singular_description: "Removing this {{ nodeType }} will remove it from the plan, but will still exist in it's original plan",
        singular_confirm_button: "Remove {{ nodeType }}",
        multi_title: "Delete {{ count }} items",
        multi_description:
            "Deleting these items will delete all of the content too.\n \nImported / linked layers will be removed from the plan, but will still exist in it's original plan.",
        multi_confirm_button: "Delete items",
    },
    cannot_drop_folder_into_its_sub_folder: "Cannot drop a folder into its' own sub folder",
    drag_and_drop: {
        moving_multiple_items: "Moving {{ count }} items...",
    },
    create_map_location: "Save in folder",
} as const;

export const library2 = {
    move_node: {
        title: "Choose a new location for the {{ nodeType }}",
        multi_title: "Choose a new location for the {{ count }} selected items",
        empty_title: "Choose a new location",
        success: "The item(s) have been moved successfully.",
        multiple_items: "Multiple items",
    },
    permissions: {
        request_access: "Request access",
        no_read_permissions: "You do not have read permissions on this item",
        no_write_permissions: "You do not have write permissions on this item",
    },
    mappingLibraries: {
        "My Workspace": "My Workspace",
        "My workspace": "My Workspace",
        "Team Workspace": "Team Workspace",
        "Team workspace": "Team Workspace",
        "Shared library": "Team Workspace",
        "Recently viewed": "Recently Viewed",
        "Recently Viewed": "Recently Viewed",
        Favourites: "Favourites",
    },
} as const;
