import * as Sentry from "@sentry/react";
import { MetricTags, MetricName, MetricEventName, MetricTagName } from "../metric-types";
import { TagName, TagValue } from "../../tags/tag-types";

/**
 * Measure the amount of time it takes to complete a certain amount of events.
 *
 * For example: "Loading a map"
 *
 * Events:
 * - Load mapbox
 * - Load sidebar
 *
 * Once the two events have completed the an event will be sent to Sentry with the total time it took to complete all events.
 *
 * @param metricName The name of the metric
 * @param amountOfEventsToComplete The amount of events that need to be completed before the metric is sent to Sentry
 */
export class TimedPerformanceMetric {
    private readonly metricName: MetricName;

    private readonly amountOfEventsToComplete: number;

    private finishedEvents: MetricEventName[] = [];

    private startTime: number | null = null;

    private endTime: number | null = null;

    private tags: MetricTags = {};

    constructor(metricName: MetricName, amountOfEventsToComplete: number) {
        this.amountOfEventsToComplete = amountOfEventsToComplete;
        this.metricName = metricName;
    }

    public start() {
        this.finishedEvents = [];
        this.startTime = performance.now();
        this.endTime = null;
    }

    public finishEvent(eventName: MetricEventName) {
        if (!this.finishedEvents.includes(eventName)) {
            this.finishedEvents.push(eventName);

            if (this.startTime == null) {
                return;
            }

            if (this.finishedEvents.length >= this.amountOfEventsToComplete) {
                this.finish();
            }
        }
    }

    public setTags(tags: Partial<Record<TagName | MetricTagName, TagValue>>) {
        this.tags = tags;
    }

    public cancel() {
        this.startTime = null;
        this.endTime = null;
        this.finishedEvents = [];
    }

    private finish() {
        if (this.startTime == null) {
            return;
        }

        this.endTime = performance.now();
        const totalTime = (this.endTime - this.startTime) / 1000;
        Sentry.metrics.distribution(this.metricName, totalTime, {
            tags: this.tags,
            unit: "second",
        });
    }
}
