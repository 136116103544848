/* eslint-disable prettier/prettier */
import { PasswordValidator, TermsAndConditionsCheckbox, MarketingCheckbox } from "@iventis/components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SolidButton, inlineTextIconMargin, inputHeight, Body2, fontSizes, borderRadius, InteractiveElement, styled } from "@iventis/styles";
import { Theme } from "@emotion/react";
import { Content } from "@iventis/translations";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { TextField, useTheme } from "@mui/material";
import { useFormik } from "formik";
import React, { FunctionComponent, useEffect, useState } from "react";
import { SignUpRequest } from "@iventis/domain-model/model/signUpRequest";
import { SUPPORT_EMAIL } from "@iventis/utilities";
import { flushSessionReplay, setMonitoringTag, setMonitoringUser, TagName } from "@iventis/observability-and-monitoring";
import { signupSchema } from "./sign-up-helpers";
import { baseURL, devMode } from "../api";

export interface SignUp extends SignUpRequest {
    termsAndConditions: boolean;
}

const emptySignUpDetails: SignUp = {
    id: undefined,
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    termsAndConditions: false,
    marketingOptIn: false,
};

const PASSWORD_INPUT_ID = "password";

const loginLink = ["sign-up-dev.iventis.com", "localhost:8003"].includes(window.location.host) ? "https://dev.iventis.studio" : "https://app.iventis.com";

enum EmailStatus {
    InUse = "in use",
}

const signInLink = devMode ? "https://dev.iventis.studio/" : "https://app.iventis.com/";

export const SignUpPage: FunctionComponent = () => {
    const translate = useIventisTranslate();
    const theme = useTheme<Theme>();

    const [previousEmail, setPreviousEmail] = useState<string>(null);
    const [submitting, setSubmitting] = useState(false);
    const [signUpError, setSignupError] = useState(false);

    useEffect(() => {
        // eslint-disable-next-line dot-notation
        window["_fs_is_outer_script"] = true;
    }, []);

    const submitData = async (data: SignUpRequest) => {
        // eslint-disable-next-line dot-notation
        if (window["isDev"]) {
            await signUp(data);
        } else {
            await new Promise<void>((resolve) => {
                // eslint-disable-next-line dot-notation
                window["dataLayer"].push({
                    event: "FREE_TRIAL_STARTED",
                    eventCallback() {
                        resolve();
                    },
                });
            });
            signUp(data);
        }
    };

    const signUp = async (data: SignUpRequest) => {
        try {
            const res = await fetch(`${baseURL()}/sign-up`, {
                method: "Post",
                headers: {
                    "Content-Type": "application/json",
                    mode: "no-cors",
                },
                body: JSON.stringify(data),
            });

            const response = await res.text();
            if (res.status === 200) {
                setMonitoringUser({
                    email: data.email,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    id: null,
                    externalUser: false,
                });
                setMonitoringTag(TagName.SIGN_UP, true);
                // Ensure the session replay is sent and stopped before navigating away
                await flushSessionReplay();
                // eslint-disable-next-line iventis/no-href-assignments
                window.parent.location.href = response;
            } else if (response.includes("Email already exists")) {
                setPreviousEmail(data.email);
                setStatus({ email: EmailStatus.InUse });
                setSubmitting(false);
            } else if (response.includes("Invalid: Password does not meet complexity requirements")) {
                setSubmitting(false);
                setErrors({ [PASSWORD_INPUT_ID]: translate(Content.login2.passwordConditions.commonPassword) });
            } else {
                setSignupError(true);
                setSubmitting(false);
            }
        } catch (err) {
            setSignupError(true);
            setSubmitting(false);
            // eslint-disable-next-line no-console
            console.error("error", err);
        }
    };

    const inputProps = {
        borderRadius: borderRadius.signUp,
        backgroundColor: theme.typographyColors.blank,
        color: theme.typographyColors.core,
        fontWeight: 500,
        input: {
            // Makes placeholder text less faded
            "&::placeholder": {
                opacity: 0.7,
            },
        },
    };

    const { touched, errors, values, handleChange, handleSubmit, setErrors, status, setStatus } = useFormik({
        initialValues: emptySignUpDetails,
        validationSchema: signupSchema(translate),
        onSubmit: (data) => {
            setSubmitting(true);
            submitData(data);
        },
    });

    return (
        <form onSubmit={handleSubmit} id="freetrial">
            <SignupContainer>
                <div style={{ display: "flex", width: "100%", gap: "20px" }}>
                    <FieldContainer>
                        <MinHeightTextField
                            id="firstName"
                            name="firstName"
                            value={values.firstName}
                            onChange={handleChange}
                            error={touched.firstName && Boolean(errors.firstName)}
                            helperText={touched.firstName && errors.firstName}
                            InputProps={{ sx: inputProps }}
                            placeholder={translate(Content.login.labels.firstName)}
                            data-testid="first-name"
                        />
                    </FieldContainer>
                    <FieldContainer>
                        <MinHeightTextField
                            id="lastName"
                            name="lastName"
                            value={values.lastName}
                            onChange={handleChange}
                            error={touched.lastName && Boolean(errors.lastName)}
                            helperText={touched.lastName && errors.lastName}
                            InputProps={{ sx: inputProps }}
                            placeholder={translate(Content.login.labels.lastName)}
                            data-testid="last-name"
                        />
                    </FieldContainer>
                </div>
                <FieldContainer>
                    <StyledTextField
                        id="email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        error={touched.email && Boolean(errors.email)}
                        helperText={touched.email && errors.email}
                        InputProps={{ sx: inputProps }}
                        placeholder={translate(Content.login.labels.email)}
                        data-testid="email"
                    />
                    {status?.email === EmailStatus.InUse && previousEmail === values.email && (
                        <EmailInUseErrorText>
                            {`${translate(Content.login.error.emailInUse[0])} `}
                            &nbsp;
                            <StyledSignInInteractiveElement
                                onClick={() => {
                                    window.parent.location = loginLink;
                                }}
                            >
                                {translate(Content.login.error.emailInUse[1])}
                            </StyledSignInInteractiveElement>
                            &nbsp;
                            {` ${translate(Content.login.error.emailInUse[2])}`}
                        </EmailInUseErrorText>
                    )}
                </FieldContainer>
                <FieldContainer>
                    <StyledTextField
                        id={PASSWORD_INPUT_ID}
                        name="password"
                        type="password"
                        value={values.password}
                        onChange={handleChange}
                        error={touched.password && Boolean(errors.password)}
                        helperText={touched.password && errors.password}
                        InputProps={{ sx: inputProps }}
                        placeholder={translate(Content.login.labels.password)}
                        data-testid="password"
                    />
                </FieldContainer>
                <StyledPasswordValidator password={values.password} stacked={false} />
                <hr className="rule" />
                <MarketingCheckbox className="checkbox" onChange={handleChange} value={values.marketingOptIn} data-testid="marketing-opt-in" />
                <TermsAndConditionsCheckbox className="checkbox" onChange={handleChange} errors={errors} touched={touched} />
                <div className="form-button-container">
                    <StartFreeTrialButton color="primary" key="create-account" type="submit" variant="contained" disabled={submitting} data-testid="submit-button">
                        <p className="button-text">
                            <span>{translate(Content.login2.createAccount.free_trial)}</span>
                            <span> </span>
                            {submitting ? (
                                <FontAwesomeIcon style={{ marginRight: inlineTextIconMargin }} icon={{ prefix: "fas", iconName: "circle-notch" }} className="fa-spin" />
                            ) : (
                                <FontAwesomeIcon icon={{ prefix: "far", iconName: "arrow-right" }} size="lg" className="arrow-icon" />
                            )}
                        </p>
                    </StartFreeTrialButton>
                    <Body2 className="error-message">
                        {signUpError && (
                            <span>{`${translate(Content.login2.createAccount.error)} ${SUPPORT_EMAIL} ${translate(Content.login2.createAccount.disableAdBlocker)}`}</span>
                        )}
                    </Body2>
                    <Body2 className="iventis-account">
                        {translate(Content.login2.createAccount.iventisAccount)}{" "}
                        <InteractiveElement
                            className="sign-in"
                            onClick={() => {
                                window.parent.location = signInLink;
                            }}
                        >
                            {translate(Content.login.links.signIn)}
                        </InteractiveElement>
                    </Body2>
                </div>
            </SignupContainer>
        </form>
    );
};

const SignupContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;

    .error-message {
        height: 20px;
        color: ${({ theme }: { theme: Theme }) => theme.toastColours.error};
    }

    .checkbox {
        align-self: flex-start;
        margin-top: 0;
        color: ${({ theme }: { theme: Theme }) => theme.typographyColors.blank};
        .checkbox-link {
            color: ${({ theme }: { theme: Theme }) => theme.typographyColors.blank};
            text-decoration-color: ${({ theme }: { theme: Theme }) => theme.typographyColors.blank};
        }
    }

    .iventis-account {
        color: ${({ theme }: { theme: Theme }) => theme.typographyColors.blank};

        .sign-in {
            color: ${({ theme }: { theme: Theme }) => theme.typographyColors.blank};
            text-decoration: underline;
        }
    }

    .rule {
        border: 1px solid ${({ theme }: { theme: Theme }) => theme.otherColors.separatorLight};
        margin: 5px 0px;
    }

    .form-button-container {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
`;

const StyledPasswordValidator = styled(PasswordValidator)`
    align-self: flex-start;
    width: 100%;
    color: ${({ theme }: { theme: Theme }) => theme.typographyColors.blank};
`;

const FieldContainer = styled.div`
    position: relative;
    width: 100%;
    flex: 1;
    .MuiTextField-root {
        width: 100%;
    }
`;

const StartFreeTrialButton = styled(SolidButton)`
    width: 100%;
    .button-text {
        gap: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    :disabled {
        color: ${({ theme }: { theme: Theme }) => theme.typographyColors.blank};
        background-color: ${({ theme }: { theme: Theme }) => theme.primaryColors.subdued70};
    }
`;

const MinHeightTextField = styled(TextField)`
    height: ${inputHeight};
    margin-bottom: 15px;

    .MuiOutlinedInput-input {
        font-size: ${fontSizes.medium} !important;
    }
`;

const StyledTextField = styled(MinHeightTextField)`
    width: 100%;
`;

const EmailInUseErrorText = styled.p`
    color: ${({ theme }: { theme: Theme }) => theme.toastColours.error};
    font-size: 0.85rem;
    position: absolute;
    top: calc(${inputHeight} + 5px);
    a {
        color: ${({ theme }: { theme: Theme }) => theme.toastColours.error};
        text-decoration: underline;
        font-weight: 600;
    }
`;

const StyledSignInInteractiveElement = styled(InteractiveElement)`
    text-decoration: underline;
    font-weight: bold;
`;
